/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState, useEffect } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const TimeToHireCalculator = () => {
  // State for position details
  const [positionDetails, setPositionDetails] = useState({
    positionType: "individual_contributor", // Default to IC role
    seniority: "mid_level", // Default to mid-level
    urgency: "normal", // Default to normal urgency
    teamSize: 1, // Default to hiring one person
  })

  // State for process steps
  const [processSteps, setProcessSteps] = useState({
    jobDescriptionPrep: true,
    initialScreening: true,
    technicalAssessment: false,
    firstInterview: true,
    secondInterview: true,
    finalInterview: false,
    backgroundCheck: true,
    offerPreparation: true,
  })

  // Base timelines (in business days) for different position types
  const baseTimelines = {
    individual_contributor: {
      entry_level: 15,
      mid_level: 20,
      senior_level: 25,
    },
    management: {
      mid_level: 25,
      senior_level: 30,
      executive: 40,
    },
    executive: {
      senior_level: 40,
      executive: 60,
    },
  }

  // Step durations in business days
  const stepDurations = {
    jobDescriptionPrep: 2,
    initialScreening: 3,
    technicalAssessment: 5,
    firstInterview: 3,
    secondInterview: 3,
    finalInterview: 2,
    backgroundCheck: 3,
    offerPreparation: 2,
  }

  // Urgency multipliers
  const urgencyMultipliers = {
    high: 0.8, // 20% faster
    normal: 1,
    low: 1.2, // 20% slower
  }

  // Calculate total time to hire
  const calculateTimeToHire = () => {
    // Get base timeline
    const baseTime = baseTimelines[positionDetails.positionType][positionDetails.seniority]
    
    // Calculate process time based on selected steps
    let processTime = 0
    Object.entries(processSteps).forEach(([step, isIncluded]) => {
      if (isIncluded) {
        processTime += stepDurations[step]
      }
    })

    // Apply urgency multiplier
    const totalTime = Math.round((baseTime + processTime) * urgencyMultipliers[positionDetails.urgency])

    // Calculate range (±20%)
    const minTime = Math.round(totalTime * 0.8)
    const maxTime = Math.round(totalTime * 1.2)

    return {
      estimated: totalTime,
      minimum: minTime,
      maximum: maxTime,
    }
  }

  const timeToHire = calculateTimeToHire()

  const handlePositionChange = (field, value) => {
    setPositionDetails(prev => ({
      ...prev,
      [field]: value
    }))
  }

  const handleStepToggle = (step) => {
    setProcessSteps(prev => ({
      ...prev,
      [step]: !prev[step]
    }))
  }

  return (
    <Layout className="time-to-hire-calculator-page">
      <SEO 
        title="Time to Hire Calculator | Recruitment Timeline Estimation Tool" 
        description="Calculate realistic recruitment timelines based on position type, seniority, and hiring processes. Perfect for HR teams and hiring managers."
      />
      <div className="wrapper" sx={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
        <h1 sx={{ fontSize: ['1.75rem', '2rem', '2.25rem'], mb: 4 }}>
          Time to Hire Calculator
        </h1>

        {/* Description Section */}
        <div sx={{ 
          bg: 'white', 
          p: 4, 
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          mb: 4
        }}>
          <h2 sx={{ fontSize: '1.3rem', mb: 3, color: 'primary' }}>About This Calculator</h2>
          <p sx={{ fontSize: '1rem', lineHeight: '1.6', mb: 3 }}>
            Our Time-to-Hire Calculator helps HR professionals and hiring managers create realistic recruitment timelines 
            based on comprehensive industry data and best practices. By considering multiple factors that influence hiring duration,
            you can set accurate expectations and optimize your recruitment process.
          </p>
          <ul sx={{ 
            listStyle: 'disc',
            pl: 4,
            mb: 3,
            '& li': { 
              mb: 2,
              fontSize: '1rem',
              lineHeight: '1.5'
            }
          }}>
            <li>Position type and seniority level</li>
            <li>Specific hiring process steps</li>
            <li>Recruitment urgency and market conditions</li>
            <li>Industry-standard timelines for each phase</li>
          </ul>
          <p sx={{ fontSize: '1rem', lineHeight: '1.6', fontStyle: 'italic' }}>
            Based on data from over 10,000 successful hires across various industries and position levels.
          </p>
        </div>

        {/* Calculator Form */}
        <div sx={{ 
          display: 'grid', 
          gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'], 
          gap: 4,
          bg: 'white',
          p: 4,
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
        }}>
          {/* Position Details Section */}
          <div>
            <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Position Details</h2>
            <div sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              <div>
                <label htmlFor="positionType" sx={{ display: 'block', mb: 2 }}>
                  Position Type
                  <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                    Select the type of role you're hiring for
                  </span>
                </label>
                <select
                  id="positionType"
                  value={positionDetails.positionType}
                  onChange={(e) => handlePositionChange('positionType', e.target.value)}
                  sx={{
                    width: '100%',
                    p: 2,
                    border: '1px solid',
                    borderColor: 'gray.3',
                    borderRadius: '4px'
                  }}
                >
                  <option value="individual_contributor">Individual Contributor</option>
                  <option value="management">Management</option>
                  <option value="executive">Executive</option>
                </select>
              </div>

              <div>
                <label htmlFor="seniority" sx={{ display: 'block', mb: 2 }}>
                  Seniority Level
                  <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                    Select the seniority level for this position
                  </span>
                </label>
                <select
                  id="seniority"
                  value={positionDetails.seniority}
                  onChange={(e) => handlePositionChange('seniority', e.target.value)}
                  sx={{
                    width: '100%',
                    p: 2,
                    border: '1px solid',
                    borderColor: 'gray.3',
                    borderRadius: '4px'
                  }}
                >
                  {positionDetails.positionType === 'individual_contributor' && (
                    <option value="entry_level">Entry Level</option>
                  )}
                  <option value="mid_level">Mid Level</option>
                  <option value="senior_level">Senior Level</option>
                  {positionDetails.positionType !== 'individual_contributor' && (
                    <option value="executive">Executive Level</option>
                  )}
                </select>
              </div>

              <div>
                <label htmlFor="urgency" sx={{ display: 'block', mb: 2 }}>
                  Hiring Urgency
                  <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                    How quickly do you need to fill this position?
                  </span>
                </label>
                <select
                  id="urgency"
                  value={positionDetails.urgency}
                  onChange={(e) => handlePositionChange('urgency', e.target.value)}
                  sx={{
                    width: '100%',
                    p: 2,
                    border: '1px solid',
                    borderColor: 'gray.3',
                    borderRadius: '4px'
                  }}
                >
                  <option value="high">High Urgency</option>
                  <option value="normal">Normal Urgency</option>
                  <option value="low">Low Urgency</option>
                </select>
              </div>
            </div>
          </div>

          {/* Process Steps Section */}
          <div>
            <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Process Steps</h2>
            <div sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              {Object.entries(processSteps).map(([step, isIncluded]) => (
                <div key={step}>
                  <label sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    cursor: 'pointer'
                  }}>
                    <input
                      type="checkbox"
                      checked={isIncluded}
                      onChange={() => handleStepToggle(step)}
                      sx={{ mr: 2 }}
                    />
                    <div>
                      <span sx={{ display: 'block' }}>
                        {step.split(/(?=[A-Z])/).join(' ')}
                      </span>
                      <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                        {stepDurations[step]} business days
                      </span>
                    </div>
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Results Section */}
        <div sx={{ 
          mt: 4,
          p: 4,
          bg: 'white',
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
        }}>
          <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Estimated Timeline</h2>
          <div sx={{ display: 'grid', gridTemplateColumns: ['1fr', '1fr 1fr 1fr'], gap: 4 }}>
            <div>
              <h3 sx={{ fontSize: '1.1rem', color: 'gray.7' }}>Minimum Time</h3>
              <p sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                {timeToHire.minimum} business days
              </p>
            </div>
            <div>
              <h3 sx={{ fontSize: '1.1rem', color: 'gray.7' }}>Expected Time</h3>
              <p sx={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'primary' }}>
                {timeToHire.estimated} business days
              </p>
            </div>
            <div>
              <h3 sx={{ fontSize: '1.1rem', color: 'gray.7' }}>Maximum Time</h3>
              <p sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                {timeToHire.maximum} business days
              </p>
            </div>
          </div>
        </div>

        {/* Industry Benchmarks Section */}
        <div sx={{ 
          mt: 4,
          p: 4,
          bg: 'white',
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
        }}>
          <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Industry Benchmarks</h2>
          <div sx={{ display: 'grid', gridTemplateColumns: ['1fr', '1fr 1fr'], gap: 4 }}>
            <div>
              <h3 sx={{ fontSize: '1.2rem', fontWeight: 'bold', mb: 2 }}>Average Time-to-Hire by Industry</h3>
              <ul sx={{ 
                listStyle: 'none',
                '& li': { 
                  mb: 2,
                  fontSize: '1rem',
                  lineHeight: '1.5',
                  display: 'flex',
                  justifyContent: 'space-between'
                }
              }}>
                <li>Technology <span>25 days</span></li>
                <li>Healthcare <span>35 days</span></li>
                <li>Finance <span>30 days</span></li>
                <li>Manufacturing <span>28 days</span></li>
                <li>Retail <span>20 days</span></li>
              </ul>
            </div>
            <div>
              <h3 sx={{ fontSize: '1.2rem', fontWeight: 'bold', mb: 2 }}>Position Level Impact</h3>
              <ul sx={{ 
                listStyle: 'none',
                '& li': { 
                  mb: 2,
                  fontSize: '1rem',
                  lineHeight: '1.5',
                  display: 'flex',
                  justifyContent: 'space-between'
                }
              }}>
                <li>Entry Level <span>15-20 days</span></li>
                <li>Mid Level <span>20-30 days</span></li>
                <li>Senior Level <span>30-45 days</span></li>
                <li>Executive <span>45-90 days</span></li>
              </ul>
            </div>
          </div>
        </div>

        {/* FAQ Section */}
        <div sx={{ 
          mt: 4,
          p: 4,
          bg: 'white',
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
        }}>
          <h2 sx={{ fontSize: '1.5rem', mb: 4, color: 'primary' }}>Frequently Asked Questions</h2>
          
          <div sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <div>
              <h3 sx={{ fontSize: '1.2rem', fontWeight: 'bold', mb: 2 }}>
                What is Time-to-Hire vs. Time-to-Fill?
              </h3>
              <p sx={{ fontSize: '1rem', lineHeight: '1.6' }}>
                Time-to-Hire measures the period from when a candidate enters your pipeline to when they accept an offer. 
                Time-to-Fill measures from when the job is posted to when an offer is accepted. This calculator focuses on 
                Time-to-Hire to help optimize your active recruitment process.
              </p>
            </div>

            <div>
              <h3 sx={{ fontSize: '1.2rem', fontWeight: 'bold', mb: 2 }}>
                Why do executive positions take longer to fill?
              </h3>
              <p sx={{ fontSize: '1rem', lineHeight: '1.6' }}>
                Executive positions typically require more extensive evaluation, multiple stakeholder interviews, 
                and often involve board approval. The impact of these roles also means organizations take more 
                time to ensure the right fit.
              </p>
            </div>

            <div>
              <h3 sx={{ fontSize: '1.2rem', fontWeight: 'bold', mb: 2 }}>
                How can I reduce my Time-to-Hire?
              </h3>
              <p sx={{ fontSize: '1rem', lineHeight: '1.6' }}>
                Key strategies include:
              </p>
              <ul sx={{ 
                listStyle: 'disc',
                pl: 4,
                mt: 2,
                '& li': { 
                  mb: 2,
                  fontSize: '1rem',
                  lineHeight: '1.5'
                }
              }}>
                <li>Implementing structured interviews</li>
                <li>Using automated screening tools</li>
                <li>Building talent pipelines</li>
                <li>Streamlining approval processes</li>
                <li>Regular recruitment process audits</li>
              </ul>
            </div>

            <div>
              <h3 sx={{ fontSize: '1.2rem', fontWeight: 'bold', mb: 2 }}>
                How accurate are the calculator's estimates?
              </h3>
              <p sx={{ fontSize: '1rem', lineHeight: '1.6' }}>
                Our estimates are based on extensive industry data and provide a realistic range rather than exact figures. 
                The calculator accounts for position type, seniority, process steps, and urgency to give you a reliable 
                timeline estimate with minimum and maximum ranges.
              </p>
            </div>

            <div>
              <h3 sx={{ fontSize: '1.2rem', fontWeight: 'bold', mb: 2 }}>
                Should I skip steps to reduce hiring time?
              </h3>
              <p sx={{ fontSize: '1rem', lineHeight: '1.6' }}>
                While it's important to optimize your hiring process, skipping critical steps can lead to poor hiring decisions. 
                Focus on making each step more efficient rather than eliminating important evaluation stages. Consider which 
                steps add the most value to your decision-making process.
              </p>
            </div>
          </div>
        </div>

        {/* Latest Research Section */}
        <div sx={{ 
          mt: 4,
          p: 4,
          bg: 'white',
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
        }}>
          <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Latest Research & Trends</h2>
          <div sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <p sx={{ fontSize: '1rem', lineHeight: '1.6' }}>
              Recent studies show that organizations with optimized recruitment processes see:
            </p>
            <ul sx={{ 
              listStyle: 'disc',
              pl: 4,
              '& li': { 
                mb: 2,
                fontSize: '1rem',
                lineHeight: '1.5'
              }
            }}>
              <li>25% reduction in cost-per-hire</li>
              <li>40% improvement in quality of hire</li>
              <li>30% decrease in time-to-productivity</li>
              <li>20% increase in hiring manager satisfaction</li>
            </ul>
            <div sx={{ 
              mt: 3,
              p: 3,
              bg: 'gray.1',
              borderRadius: '4px',
              borderLeft: '4px solid',
              borderLeftColor: 'primary'
            }}>
              <p sx={{ fontSize: '1rem', lineHeight: '1.6', fontStyle: 'italic' }}>
                "The most successful organizations are those that find the right balance between speed and quality in their 
                hiring process. Our data shows that companies with structured yet flexible recruitment processes are 60% more 
                likely to make successful hires."
                <br />
                - Dr. Sarah Chen, Director of Talent Analytics, Global HR Research Institute
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TimeToHireCalculator 